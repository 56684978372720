import React, { ReactNode } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

export const colors = {
  primary: '#51DAE4',
  primaryDarker: '#1FB0BC',
  primaryLight: '#F1E8DF',
  darkest: '#222323',
  gray: '#B9B9B9',
  white: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    text: {
      primary: colors.darkest,
      secondary: colors.gray,
    },
  },
});

export function AppThemeProvider({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
