import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/styles/withStyles';
import DialogContent from '@material-ui/core/DialogContent';
import { colors } from '../../theme/defaultTheme';

export const HeaderBar = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    '@media (max-width: 768px)': {
      height: 64,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      boxShadow: 'none',
      '& .drawer-container': {
        display: 'flex!important',
      },
    },
  },
}))(AppBar);

export const HeaderDetails = withStyles(() => ({
  root: {
    backgroundColor: colors.primaryDarker,
    padding: '8px 36px',
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%',
    height: 54,
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      margin: '0 8px',
      '&:last-child': {
        marginRight: 0,
      },
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
}))(Box);

export const HeaderContentContainer = withStyles(() => ({
  root: {
    padding: '8px 36px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 54,
    color: '#000',
    borderBottom: '1px solid #C7C7C7',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
}))(Box);

export const HeaderContentLogoContainer = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    '& svg': {
      width: 'auto',
      height: 32,
    },
  },
}))(Box);

export const HeaderCategories = withStyles(() => ({
  root: {
    padding: '8px 36px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 54,
    color: '#000',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
}))(Box);

export const LayoutContainer = withStyles(() => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    overflowY: 'auto',
    padding: '28px 36px',
    '@media (max-width: 768px)': {
      marginTop: 0,
      padding: '72px 18px 16px',
      height: 'calc(100vh)',
    },
    position: 'relative',
  },
}))(Box);

export const InputGridContainer = withStyles(() => ({
  root: {
    fontSize: 12,
    color: '#959595',
    marginBottom: 20,
    position: 'relative',
  },
}))(Grid);

export const InputLabelGridContainer = withStyles(() => ({
  root: {
    fontSize: 12,
    color: '#959595',
  },
}))(Grid);

export const InputGridItemContainer = withStyles(() => ({
  root: {
    fontSize: 12,
    paddingTop: 6,
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
}))(Grid);

export const InputTextField = withStyles(() => ({
  root: {
    backgroundColor: '#F5F5F5',
    '& input': {
      fontSize: 12,
      border: '1px solid #E5E5E5',
      borderRadius: 0,
      padding: '6px 0 6px 18px!important',
      boxSizing: 'border-box',
      minHeight: 36,
    },
    '& textarea': {
      fontSize: 12,
      border: '1px solid #E5E5E5',
      borderRadius: 0,
      padding: '6px 0 6px 18px!important',
      boxSizing: 'border-box',
      minHeight: 100,
    },
    '& input.Mui-disabled, textarea.Mui-disabled, .MuiInputAdornment-root.disabled-adornment': {
      backgroundColor: '#F8F9FB',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
    '& .MuiInputAdornment-root': {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 0,
      borderColor: '#E5E5E5',
      borderStyle: 'solid',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: 36,
      marginRight: 0,
      marginLeft: 0,
      boxSizing: 'border-box',
      maxHeight: 'unset',
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontSize: '12px!important',
      },
      '&.MuiInputAdornment-positionEnd': {
        borderRightWidth: 1,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderLeft: 0,
        paddingRight: 10,
      },
      '&.MuiInputAdornment-positionStart': {
        paddingLeft: 8,
      },
    },
    '& .MuiInput-input.MuiInputBase-inputAdornedStart': {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      padding: '6px 0 6px 10px!important',
    },
    '& .MuiInput-input.MuiInputBase-inputAdornedEnd': {
      borderRight: 0,
      borderLeftWidth: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      padding: '6px 10px 6px 18px!important',
    },
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
  },
}))(TextField);

export const LoginInputTextField = withStyles(() => ({
  root: {
    backgroundColor: '#F5F5F5',
    borderRadius: 7,
    '& input': {
      fontSize: 12,
      borderRadius: 6,
      padding: '6px 0 6px 18px!important',
      boxSizing: 'border-box',
      minHeight: 43,
    },
    '& textarea': {
      fontSize: 12,
      border: '1px solid #E5E5E5',
      borderRadius: 0,
      padding: '6px 0 6px 18px!important',
      boxSizing: 'border-box',
      minHeight: 100,
    },
    '& input.Mui-disabled, textarea.Mui-disabled, .MuiInputAdornment-root.disabled-adornment': {
      backgroundColor: '#F8F9FB',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
    '& .MuiInputAdornment-root': {
      backgroundColor: '#F5F5F5',
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderColor: '#E5E5E5',
      borderStyle: 'solid',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: 36,
      marginRight: 0,
      marginLeft: 0,
      boxSizing: 'border-box',
      maxHeight: 'unset',
      cursor: 'pointer',
      '& .MuiTypography-root': {
        fontSize: '12px!important',
      },
      '&.MuiInputAdornment-positionEnd': {
        borderRightWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderLeft: 0,
        paddingRight: 10,
      },
      '&.MuiInputAdornment-positionStart': {
        paddingLeft: 8,
      },
    },
    '& .MuiInput-input.MuiInputBase-inputAdornedStart': {
      borderLeft: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      padding: '6px 0 6px 10px!important',
    },
    '& .MuiInput-input.MuiInputBase-inputAdornedEnd': {
      borderRight: 0,
      borderLeftWidth: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      padding: '6px 10px 6px 18px!important',
    },
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
  },
}))(TextField);

export const DropdownSearchableField = withStyles(() => ({
  root: {
    border: '1px solid #E5E5E5',
    borderRadius: 0,
    padding: '6px 6px 6px 18px!important',
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    minHeight: 44,
    maxHeight: 100,
    overflowY: 'auto',
    '& input': {
      fontSize: 12,
      paddingLeft: '0!important',
    },
    '& input.Mui-disabled': {
      backgroundColor: '#F8F9FB',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
  },
}))(TextField);

export const LoginDropdownSearchableField = withStyles(() => ({
  root: {
    borderRadius: 5,
    padding: '6px 6px 6px 18px!important',
    boxSizing: 'border-box',
    backgroundColor: '#F5F5F5',
    minHeight: 44,
    maxHeight: 100,
    overflowY: 'auto',
    '& input': {
      fontSize: 12,
      paddingLeft: '0!important',
    },
    '& input.Mui-disabled': {
      backgroundColor: '#F8F9FB',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
  },
}))(TextField);

export const InputIconRadioButton = withStyles(() => ({
  root: {
    color: '#FFF',
    textTransform: 'none',
    padding: 0,
    width: 32,
    height: 32,
  },
}))(IconButton);

export const InputSelect = withStyles(() => ({
  root: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: 0,
    padding: 12,
  },
}))(Select);

export const InputChip = withStyles(() => ({
  root: {
    fontSize: 12,
    height: 24,
    '& .svg': {
      width: 20,
    },
  },
}))(Chip);

export const CategoryTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    minWidth: 'unset',
    width: 'unset',
    padding: 0,
    marginRight: 16,
    '&.MuiTab-textColorInherit:not(.Mui-selected)': {
      color: '#969696',
      opacity: 1,
    },
  },
}))(Tab);

export const InputButton = withStyles(() => ({
  root: {
    minWidth: 100,
    textTransform: 'none',
    boxSizing: 'border-box',
    backgroundColor: colors.primary,
    color: colors.white,
    border: 'none',
    height: 48,
    borderRadius: 5,
    fontWeight: 'bold',
    '&.MuiButton-outlined': {
      backgroundColor: colors.white,
      color: colors.primary,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '24px!important',
    },
    '&:hover': {
      backgroundColor: colors.primary,
    },
    '&.MuiButton-outlined:hover': {
      backgroundColor: colors.white,
    },
  },
}))(Button);

export const ProductCardContainer = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '90%',
    marginRight: 16,
    '&:last-child': {
      marginRight: 0,
    },
    '@media (max-width: 768px)': {
      width: '100%',
      marginBottom: 32,
    },
  },
}))(Box);

export const ProductCardImageContainer = withStyles(() => ({
  root: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    height: 280,
    padding: 16,
    backgroundColor: '#F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 12,
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 280,
      maxWidth: 250,
      objectFit: 'cover',
    },
  },
}))(Box);

export const ProductCardDescriptionContainer = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
  },
}))(Box);

export const ProductFavoriteButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: 32,
    height: 32,
    padding: 8,
    top: 12,
    right: 12,
    backgroundColor: colors.white,
    position: 'absolute',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
}))(IconButton);

export const ProductCardAddToCartButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: colors.primary,
    color: colors.white,
    height: 48,
    textTransform: 'none',
    marginTop: 12,
    borderRadius: 0,
    width: '100%',
    '& svg': {
      width: 20,
      height: 20,
    },
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
}))(Button);

export const FooterContainer = withStyles(() => ({
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: 'rgba(245, 245, 245, 0.5)',
  },
}))(Box);

export const FooterAboutUsContainer = withStyles(() => ({
  root: {
    width: '60%',
    marginBottom: 16,
    lineHeight: 1.75,
    '@media (max-width: 1200px)': {
      width: '80%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
}))(Box);

export const FooterAboutUsAppStoreContainer = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    marginBottom: 32,
    '& img': {
      height: 60,
      width: 'auto',
      position: 'relative',
      top: -3,
    },
    '& svg': {
      height: 54,
    },
    '@media (max-width: 768px)': {
      // width: '100%'
    },
  },
}))(Box);

export const FooterNavContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
    boxSizing: 'border-box',
    paddingRight: 16,
    '@media (max-width: 768px)': {
      paddingRight: 0,

      alignItems: 'flex-start',
    },
  },
}))(Box);

export const FooterContactUsGridItem = withStyles(() => ({
  root: {
    paddingLeft: 32,
    '@media (max-width: 768px)': {
      paddingLeft: 0,
    },
  },
}))(Grid);

export const PopOverDropDownButton = withStyles(() => ({
  root: {
    minWidth: 0,
    padding: 0,
    color: colors.white,
    marginLeft: 10,
  },
}))(Button);

export const LogoutButton = withStyles(() => ({
  root: {
    marginTop: 8,
    width: '100%',
    textTransform: 'capitalize',
    fontSize: 14,
    justifyContent: 'flex-start',
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 19,
      maxWidth: 19,
      objectFit: 'cover',
      marginRight: '12px',
    },
  },
}))(Button);

export const HeaderCartContainer = withStyles(() => ({
  root: {
    width: 120,
    '& a': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      color: colors.darkest,
    },
    '@media (max-width: 768px)': {
      width: 'auto',
    },
  },
}))(Box);

export const LowerCategoryBox = withStyles(() => ({
  root: {
    width: '60%',
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    padding: 60,
    overflow: 'auto',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      margin: '0 8px',
      '&:last-child': {
        marginRight: 0,
      },
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
}))(Box);

export const CategoryProductImageContainer = withStyles(() => ({
  root: {
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 250,
      maxWidth: 250,
      objectFit: 'cover',
    },
  },
}))(Box);

export const CategoryNameContainer = withStyles(() => ({
  root: {
    paddingRight: 32,
    fontSize: 12,
    color: colors.darkest,
    marginBottom: 4,
    opacity: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'start',
  },
}))(Box);

export const ProductNameContainer = withStyles(() => ({
  root: {
    fontSize: '13px',
    color: colors.darkest,
    marginBottom: '4px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingRight: 32,
    textOverflow: 'ellipsis',
    alignSelf: 'start',
  },
}))(Box);

export const UserImageContainer = withStyles(() => ({
  root: {
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 60,
      maxWidth: 60,
      objectFit: 'cover',
    },
  },
}))(Box);

export const PopoverOptionBotton = withStyles(() => ({
  root: {
    marginBottom: 8,
    marginTop: 8,
    minWidth: 100,
    textTransform: 'capitalize',
    fontSize: 14,
    justifyContent: 'flex-start',
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 19,
      maxWidth: 19,
      objectFit: 'cover',
      marginRight: '12px',
    },
  },
}))(Button);

export const PopoverContentContainer = withStyles(() => ({
  root: {
    minWidth: '320px',
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}))(Box);

export const PopoverTitleContainer = withStyles(() => ({
  root: {
    paddingBottom: '12px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #aaa',
  },
}))(Box);

export const PopoverOptionContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #aaa',
  },
}))(Box);

export const PopOverLinkContentContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    marginBottom: 8,
    marginTop: 8,
    minWidth: 100,
    textTransform: 'capitalize',
    fontSize: 14,
    justifyContent: 'flex-start',
    textDecoration: 'none',
    color: '#000',
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 19,
      maxWidth: 19,
      objectFit: 'cover',
      marginRight: '12px',
    },
  },
}))(Box);

export const ProdoctCategoryTypography = withStyles(() => ({
  root: {
    fontSize: 13,
    opacity: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Typography);

export const ProdoctNameTypography = withStyles(() => ({
  root: {
    fontSize: 20,
    fontWeight: 'bolder',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Typography);

export const ProdoctCodeTypography = withStyles(() => ({
  root: {
    fontSize: 13,
    marginBottom: 20,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Typography);

export const ProductDetailsContainer = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    height: 280,
    padding: 16,
    backgroundColor: '#F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 12,
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 300,
      maxWidth: 250,
      objectFit: 'cover',
    },
  },
}))(Box);

export const ProductDialogCardContainer = withStyles(() => ({
  root: {
    display: 'flex',
    boxSizing: 'border-box',
    width: 'calc(100% - 12px)',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
}))(Box);

export const ProductDialogCardImageContainer = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    minHeight: 300,
    padding: 20,
    backgroundColor: '#F5F5F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginRight: 15,
    '@media (max-width: 768px)': {
      marginRight: 0,
    },
    '& img': {
      width: '100%',
      height: 'auto',
      maxHeight: 300,
      maxWidth: 450,
      objectFit: 'cover',
    },
  },
}))(Box);

export const ProductDialogFavoriteButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: 32,
    height: 32,
    padding: 8,
    top: 12,
    right: 12,
    backgroundColor: '#FFF',
    position: 'absolute',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
}))(IconButton);

export const ProductCancelButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: 32,
    height: 32,
    padding: 8,
    top: 30,
    right: 30,
    backgroundColor: '#FFF',
    position: 'absolute',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
}))(IconButton);

export const ProductDialogCardDescriptionContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    marginTop: 0,
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      marginTop: 10,
      paddingLeft: 0,
    },
  },
}))(Box);

export const ProductDialogCardAddToCartButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: colors.primary,
    color: colors.white,
    height: 48,
    maxWidth: 350,
    textTransform: 'none',
    marginTop: 12,
    borderRadius: 0,
    width: '100%',
    '& svg': {
      width: 20,
      height: 20,
    },
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
}))(Button);

export const ProductCardsContainer = withStyles(() => ({
  root: {
    '& .product-card-container > .product-card': {
      width: 'calc(25% - 12px)',
      marginBottom: 24,
    },
    '& .product-card-container > .product-card:nth-child(4n)': {
      marginRight: 0,
    },
    '@media (max-width: 768px)': {
      '& .product-card-container > .product-card': {
        width: 'calc(50% - 8px)',
      },
      '& .product-card-container > .product-card:nth-child(2n)': {
        marginRight: 0,
      },
    },
  },
}))(Box);

export const NoProductMessageBox = withStyles(() => ({
  root: {
    width: '100%',
    fontWeight: 'bolder',
    fontSize: 40,
    height: 'calc(100vh - 216px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '50%',
    '@media (max-width: 768px)': {
      fontSize: 27,
    },
  },
}))(Box);

export const DialogTitleContainer = withStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #aaa',
    justifyContent: 'space-between',
  },
}))(Box);

export const ProductDialogTitle = withStyles(() => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    '@media (max-width: 768px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))(DialogTitle);

export const ProductDialogContent = withStyles(() => ({
  root: {
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 25,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 315,
    '@media (max-width: 768px)': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}))(DialogContent);

export const ProductHeaderDetails = withStyles(() => ({
  root: {
    paddingRight: 44,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(Box);
